var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "rs-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            "status-icon": "",
            size: "small",
            "label-width": "110px",
          },
        },
        [
          _c(
            "rs-form-item",
            {
              staticClass: "half_form_item",
              attrs: {
                label: "资源编码",
                prop: "resourceCode",
                rules: [
                  {
                    required: true,
                    message: "请输入资源编码",
                    trigger: "blur",
                  },
                  { max: 30, message: "最大长度为30", trigger: "blur" },
                ],
              },
            },
            [
              _c("rs-input", {
                attrs: {
                  type: "text",
                  disabled: _vm.type != 0,
                  autocomplete: "off",
                },
                model: {
                  value: _vm.ruleForm.resourceCode,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "resourceCode", $$v)
                  },
                  expression: "ruleForm.resourceCode",
                },
              }),
            ],
            1
          ),
          _c(
            "rs-form-item",
            {
              staticClass: "half_form_item",
              attrs: {
                label: "资源名称",
                prop: "resourceName",
                rules: [
                  {
                    required: true,
                    message: "请输入资源名称",
                    trigger: "blur",
                  },
                  { max: 50, message: "最大长度为50", trigger: "blur" },
                ],
              },
            },
            [
              _c("rs-input", {
                attrs: { type: "text", autocomplete: "off" },
                model: {
                  value: _vm.ruleForm.resourceName,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "resourceName", $$v)
                  },
                  expression: "ruleForm.resourceName",
                },
              }),
            ],
            1
          ),
          _c(
            "rs-form-item",
            {
              staticClass: "half_form_item",
              attrs: {
                label: "地址",
                prop: "resourceUrl",
                rules: [
                  { max: 1024, message: "最大长度为1024", trigger: "blur" },
                ],
              },
            },
            [
              _c("rs-input", {
                attrs: { type: "text", autocomplete: "off" },
                model: {
                  value: _vm.ruleForm.resourceUrl,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "resourceUrl", $$v)
                  },
                  expression: "ruleForm.resourceUrl",
                },
              }),
            ],
            1
          ),
          _c(
            "rs-form-item",
            {
              staticClass: "half_form_item",
              attrs: {
                label: "资源类型",
                prop: "resourceType",
                rules: [
                  {
                    required: true,
                    message: "请选择资源类型",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c(
                "rs-select",
                {
                  staticClass: "select_width",
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.ruleForm.resourceType,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "resourceType", $$v)
                    },
                    expression: "ruleForm.resourceType",
                  },
                },
                _vm._l(_vm.options, function (item) {
                  return _c("rs-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "rs-form-item",
            {
              staticClass: "half_form_item",
              attrs: {
                label: "上级资源",
                prop: "parentResourceId",
                rules: [
                  {
                    required: true,
                    message: "请选择上级资源",
                    trigger: "blur",
                  },
                ],
              },
            },
            [
              _c(
                "rs-select",
                {
                  staticClass: "select_width",
                  attrs: { placeholder: "请选择" },
                  model: {
                    value: _vm.ruleForm.parentResourceId,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "parentResourceId", $$v)
                    },
                    expression: "ruleForm.parentResourceId",
                  },
                },
                _vm._l(_vm.parentOptions, function (item) {
                  return _c("rs-option", {
                    key: item.value,
                    attrs: { label: item.label, value: item.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm.ruleForm.resourceType === 1
            ? _c(
                "rs-form-item",
                {
                  staticClass: "half_form_item",
                  attrs: { label: "一级菜单图标" },
                },
                [
                  _c("i", {
                    staticClass: "font_family",
                    domProps: { innerHTML: _vm._s(_vm.ruleForm.iconCode) },
                  }),
                  _vm.ruleForm.iconCode
                    ? _c(
                        "a",
                        {
                          staticClass: "main_font marginLeft15",
                          on: {
                            click: function ($event) {
                              _vm.iconDrawer = true
                            },
                          },
                        },
                        [_vm._v("更改")]
                      )
                    : _c(
                        "a",
                        {
                          staticClass: "main_font",
                          on: {
                            click: function ($event) {
                              _vm.iconDrawer = true
                            },
                          },
                        },
                        [_vm._v("选择")]
                      ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "drawer-button",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "rs-button",
            {
              attrs: { size: "small" },
              on: {
                click: function ($event) {
                  return _vm.result()
                },
              },
            },
            [_vm._v("取消")]
          ),
          _c(
            "rs-button",
            {
              attrs: { type: "primary", size: "small", loading: _vm.loading },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
      _c(
        "rs-dialog",
        {
          attrs: {
            title: "图标库",
            visible: _vm.iconDrawer,
            direction: "rtl",
            modal: false,
            "show-close": false,
            size: "100%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.iconDrawer = $event
            },
          },
        },
        [
          _c("icon-drawer", {
            attrs: { ruleForm: _vm.ruleForm },
            on: { closeIcon: _vm.hideIcon },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }