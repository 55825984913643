<template>
  <div class="iframe-container-box container">
    <rs-row style="background: #fff;min-height: 600px;">
      <rs-col :span="7">
        <div class="left-box" style="overflow-y: scroll">
          <div>
            <rs-input placeholder="搜索菜单名称" size="small" v-model="searchMenuName"></rs-input>
          </div>
          <div class="tree-div-box">
            <div class="mt10 tree-div">
              <rs-tree
                ref="resourceTree"
                class="resource-tree filter-tree"
                :data="treeData"
                :props="defaultProps"
                :current-node-key="selectTreeNodeKey"
                :expand-on-click-node="false"
                :render-content="renderContentTree"
                node-key="id"
                default-expand-all
                highlight-current
                :filter-node-method="filterNode"
                @node-click="handleNodeClick"
              ></rs-tree>
            </div>
          </div>
        </div>
      </rs-col>
      <rs-col :span="17">
        <!-- <div class="right-box noOrg" v-if="treeData.length==0">
                <img class="imgShow" src="../../../assets/images/no_data_org@2x.png">
                <p>暂无任何功能</p>
                <rs-button size="mini" type="primary" @click="edit('','0')">新增功能</rs-button>
            </div> -->
        <div class="right-box">
          <rs-button size="small" style="margin-bottom:14px;" type="primary" @click="edit('', '0')">新增功能</rs-button>
          <rs-table class="resource-table" :data="tableData" border resizable highlight-current-row tooltip-effect="light">
            <rs-table-column type="index" :indexMethod="indexMethod" label="序号" width="70" prop="" align="left"></rs-table-column>
            <rs-table-column label="资源编码" show-overflow-tooltip align="left">
              <template slot-scope="scope">
                <span class="resourceCode">{{ scope.row.resourceCode }}</span>
              </template>
            </rs-table-column>
            <rs-table-column label="名称" show-overflow-tooltip prop="resourceName" align="left"></rs-table-column>
            <rs-table-column label="地址" show-overflow-tooltip prop="resourceUrl" align="left"></rs-table-column>
            <rs-table-column label="资源类型" prop="resourceType" :formatter="resourceTypeFormatter" align="left"></rs-table-column>
            <!--<rs-table-column label="备注" prop="remark" width="100" align="center"></rs-table-column>-->
            <rs-table-column label="排序" align="left">
              <template slot-scope="scope">
                <div>
                  <rs-button size="small" type="text" @click="upMove(scope.row, scope)">上移</rs-button>
                </div>
                <div>
                  <rs-button size="small" type="text" @click="downMove(scope.row, scope)">下移</rs-button>
                </div>
              </template>
            </rs-table-column>
            <rs-table-column label="操作" align="left">
              <template slot-scope="scope">
                <div>
                  <rs-button size="small" @click="removeResource(scope, scope.index)" type="text">删除</rs-button>
                </div>
                <div>
                  <rs-button size="small" type="text" @click="edit(scope.row, '1')">编辑</rs-button>
                </div>
              </template>
            </rs-table-column>
          </rs-table>
        </div>
      </rs-col>
    </rs-row>
  </div>
  <!-- <rs-dialog :title="title"
            class="newProduct_block form-dialog"
            :visible.sync="drawer"
            :close-on-click-modal="false"
            top="20vh">
            <detail @hide="hide" @getTree="initResourceTreeData" :type="type" :ruleForm="ruleForm"></detail>
        </rs-dialog> -->
</template>

<script>
import Http from '@/api/api'
import utils from '@/utils/utils'
import detail from '../resourceManage/resourceDetail'
export default {
  name: 'resourceManage',
  components: { detail },
  data() {
    return {
      dialogTableVisible: false,
      title: '新建资源',
      ruleForm: {},
      iconTable: false,
      searchMenuName: '',
      treeData: [],
      // treeData:[],
      selectTreeNodeKey: 1,
      tableData: [], //treeData
      allResourceData: [], //所有资源数据
      defaultProps: {
        children: 'childResourceVoList',
        label: 'resourceName'
      },
      resourceType: '',
      resourceCode: 1,
      sortList: [],
      btnResource: {},
      options: [
        { value: 3, label: '按钮' },
        { value: 1, label: '菜单' },
        { value: 2, label: 'API' },
        { value: 4, label: '其他' }
      ],
      parentOptions: []
    }
  },
  watch: {
    searchMenuName(val) {
      console.log(val)
      this.$refs.resourceTree.filter(val)
    }
  },
  methods: {
    // 自定义树形数据样式结构
    renderContentTree(h, { node, data, store }) {
      let imgClass = data.parentResourceId == '0' ? 'org-png' : 'file-png'
      return (
        <div class="custom-tree-node" id="tree">
          <span class="flex1 tree-name" title={node.label}>
            <span class={imgClass}></span>
            <span class="tree-name-text">{node.label}</span>
          </span>
        </div>
      )
    },
    initResourceTreeData() {
      //获取所有资源
      Http.initAllResourceTree().then(res => {
        // postMessage.hideLoading('/#/systemList')
        if (res.data && res.data.data) {
          let rootNode = res.data.data
          this.allResourceData = [rootNode]
          //获取被选中子数据
          if (!this.selectTreeNodeKey) {
            this.selectTreeNodeKey = rootNode.id
            this.tableData = rootNode.childResourceVoList
            this.$refs.resourceTree.setCurrentKey(self.selectTreeNodeKey)
            this.makeSortList()
          } else {
            this.getChildData(rootNode)
          }
        } else {
          this.allResourceData = []
        }
      })
      //获取menu 数据
      Http.initAllResourceTree('', 'resourceTypes=1&resourceTypes=2&resourceTypes=4').then(res => {
        if (res.data && res.data.data) {
          let rootNode = res.data.data
          console.log(rootNode)
          this.treeData = [rootNode]
        } else {
          this.treeData = []
        }
      })
    },
    getList() {
      Http.getAllResourceList('', 'resourceTypes=1&resourceTypes=2&resourceTypes=4').then(res => {
        this.parentOptions = res.data.data.map(item => {
          return { value: item.id, label: item.resourceName }
        })
      })
    },
    getChildData(data) {
      let self = this
      if (data.id === self.selectTreeNodeKey) {
        self.tableData = data.childResourceVoList
      } else {
        if (data.childResourceVoList && data.childResourceVoList.length > 0) {
          data.childResourceVoList.forEach(item => {
            if (item.id === self.selectTreeNodeKey) {
              self.tableData = item.childResourceVoList ? item.childResourceVoList : []
            } else {
              if (item.childResourceVoList && item.childResourceVoList.length > 0) {
                self.getChildData(item)
              }
            }
          })
        }
      }
      setTimeout(() => {
        self.$refs.resourceTree.setCurrentKey(self.selectTreeNodeKey)
      }, 10)
      self.makeSortList()
    },

    resourceTypeFormatter(val) {
      if (val.resourceType === 3) {
        return '按钮'
      } else if (val.resourceType === 1) {
        return '菜单'
      } else if (val.resourceType === 4) {
        return '其他'
      } else if (val.resourceType === 2) {
        return 'API'
      }
      return ''
    },
    removeResource(scope, index) {
      let self = this
      this
        .$confirmDialog({ title: '提示', textBody: `您确定要删除${scope.row.resourceName}吗？`, width: '35%', iconType: 'warning', btnType: 2 })
        .then(async () => {
          let res = await Http.delResource({ id: scope.row.id ,resourceCode:scope.row.resourceCode})
          if (res.data.code === 200) {
            self.$message({
              message: '删除成功',
              type: 'success'
            })
            self.initResourceTreeData()
            self.getList()
          }
        })
        .catch(err => {})
    },
    upMove(item, scope) {
      let index = scope.$index
      if (index === 0) {
        return
      }
      let self = this.sortList[index]
      let next = this.sortList[index - 1]
      this.sortList.splice(index - 1, 2)
      this.sortList.splice(index - 1, 0, { id: item.id, sort: self.sort - 1 })
      this.sortList.splice(index, 0, { id: next.id, sort: next.sort + 1 })
      Http.updateResourceSort({ resourceId: this.selectTreeNodeKey, childResourceVos: this.sortList }).then(res => {
        this.initResourceTreeData()
      })
    },
    downMove(item, scope) {
      let index = scope.$index
      if (index === this.sortList.length - 1) {
        return
      }
      let self = this.sortList[index]
      let next = this.sortList[index + 1]
      this.sortList.splice(index, 2)
      this.sortList.splice(index, 0, { id: item.id, sort: self.sort + 1 })
      this.sortList.splice(index + 1, 0, { id: next.id, sort: next.sort - 1 })
      Http.updateResourceSort({ resourceId: this.selectTreeNodeKey, childResourceVos: this.sortList }).then(res => {
        this.initResourceTreeData()
      })
    },
    makeSortList() {
      this.sortList = []
      if (this.tableData) {
        this.tableData.forEach((v, i) => {
          this.sortList.push({ id: v.id, sort: i + 1 })
        })
      }
    },
    indexMethod(val) {
      // return this.pageSize * (this.pageNo-1)+val;
      return 1
    },
    handleNodeClick(val) {
      if (val) {
        this.resourceType = val.resourceType
        this.resourceCode = val.id
        this.selectTreeNodeKey = val.id
        this.getChildData(this.allResourceData[0])
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      }
    },
    //拖拽完成
    // handleDragDrop(){
    //   Http.updateResource(this.treeData).then((res)=>{
    //
    //   });
    // },
    //过滤树
    filterNode(value, data) {
      if (!value) return true
      return data.resourceName.indexOf(value) !== -1
    },
    edit(rows, type) {
      this.type = type
      let id = rows ? rows.id : ''
      if (rows) this.$store.dispatch('setResourceInfo', rows)
      this.$router.push(`resourceEdit?id=${id}&type=${type}&resourceCode=${this.resourceCode}`)
    }
  },
  created() {
    this.initResourceTreeData()
    this.getList()
  }
}
</script>
<style lang="less" scoped>
@import '../../organizationManage/style/index.less';
/deep/ .rs-table__row {
  &:hover {
    cursor: pointer;
  }
}
/deep/ .current-row {
  .resourceCode {
    color: #2ba3ff;
  }
}
</style>
