<template>
    <div>
        <rs-form :model="ruleForm" status-icon size="small" ref="ruleForm" label-width="110px" class="demo-ruleForm">
            <rs-form-item
                    class="half_form_item"
                    label="资源编码"
                    prop="resourceCode"
                    :rules="[
                      { required: true, message: '请输入资源编码', trigger: 'blur' },
                      { max:30, message: '最大长度为30', trigger: 'blur' },
                    ]"
            >
                <rs-input type="text" v-model="ruleForm.resourceCode" :disabled="type != 0" autocomplete="off"></rs-input>
            </rs-form-item>
            <rs-form-item
                    class="half_form_item"
                    label="资源名称"
                    prop="resourceName"
                    :rules="[
                      { required: true, message: '请输入资源名称', trigger: 'blur' },
                      { max:50, message: '最大长度为50', trigger: 'blur' },
                    ]"
            >
                <rs-input  type="text" v-model="ruleForm.resourceName" autocomplete="off"></rs-input>
            </rs-form-item>
            <rs-form-item
                    class="half_form_item"
                    label="地址"
                    prop="resourceUrl"
                    :rules="[
                      { max:1024, message: '最大长度为1024', trigger: 'blur' },
                    ]"
            >
                <rs-input type="text" v-model="ruleForm.resourceUrl" autocomplete="off"></rs-input>
            </rs-form-item>
            <rs-form-item
                    class="half_form_item"
                    label="资源类型"
                    prop="resourceType"
                    :rules="[
                      { required: true, message: '请选择资源类型', trigger: 'blur' },
                    ]"
            >
                <rs-select class="select_width" v-model="ruleForm.resourceType" placeholder="请选择">
                    <rs-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </rs-option>
                </rs-select>
            </rs-form-item>
            <rs-form-item
                    class="half_form_item"
                    label="上级资源"
                    prop="parentResourceId"
                    :rules="[
                      { required: true, message: '请选择上级资源', trigger: 'blur' },
                    ]">
                <rs-select class="select_width" v-model="ruleForm.parentResourceId" placeholder="请选择">
                    <rs-option
                            v-for="item in parentOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </rs-option>
                </rs-select>
            </rs-form-item>
            <rs-form-item
                    class="half_form_item"
                    label="一级菜单图标"
                    v-if="ruleForm.resourceType === 1">
                <i class="font_family" v-html="ruleForm.iconCode"></i>
                <a class="main_font marginLeft15" @click="iconDrawer = true" v-if="ruleForm.iconCode">更改</a>
                <a class="main_font" @click="iconDrawer = true" v-else>选择</a>
            </rs-form-item>
        </rs-form>
        <span slot="footer" class="drawer-button">
                <rs-button size="small"  @click="result()">取消</rs-button>
                <rs-button type="primary" size="small"  @click="submitForm('ruleForm')" :loading="loading">确认</rs-button>
        </span>
        <rs-dialog
                title="图标库"
                :visible.sync="iconDrawer"
                direction="rtl"
                :modal=false
                :show-close=false
                size="100%">
                <icon-drawer @closeIcon="hideIcon" :ruleForm="ruleForm"></icon-drawer>
        </rs-dialog>
    </div>
</template>

<script>
    import Http from '../../../api/api'
    import IconDrawer from '../resourceManage/iconDrawer'
    export default {
        name: "resourceDetail.vue",
        components:{IconDrawer},
        data(){
            return {
                parentOptions:[],
                loading:false,
                iconDrawer:false,
                options:[{value:3,label:'按钮'},{value:1,label:'菜单'},{value:4,label:'其他'}],
            }
        },
        props:["type","ruleForm"],
        methods:{
            result(status,message){//返回结果值，0为成功，1为失败，不填单纯关闭
                if(status === 0){
                    this.$message({
                        message: '保存成功！',
                        type: 'success'
                    });
                } else if(status === 1) {
                    this.$message.error(message);
                }
                this.$emit('hide')
                setTimeout(() => {
                    this.loading = false
                    this.$refs['ruleForm'].resetFields()
                })
            },
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        if(this.type == 0){
                            this.editConfig()
                        } else if(this.type == 1){
                            this.saveConfig()
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            getList(){
                Http.getAllResourceList('','resourceTypes=1&resourceTypes=2&resourceTypes=4').then((res) => {
                    this.parentOptions  = res.data.data.map(item => {
                        return {value: item.id, label: item.resourceName};
                    })
                })
            },
            editConfig(){
                Http.addResource({
                    "resourceName":this.ruleForm.resourceName,
                    "resourceCode":this.ruleForm.resourceCode,
                    "resourceType":this.ruleForm.resourceType,
                    "resourceUrl":this.ruleForm.resourceUrl,
                    "parentResourceId":this.ruleForm.parentResourceId,
                    "iconCode":this.ruleForm.iconCode
                }).then((res) => {
                    this.$emit('getTree')
                    this.getList()
                    this.$emit('hide',true)
                    this.loading = false
                }).catch((error) => {
                    this.loading = false
                })
            },
            saveConfig(){
                Http.updateResource({
                    "id":this.ruleForm.id,
                    "resourceName":this.ruleForm.resourceName,
                    "resourceCode":this.ruleForm.resourceCode,
                    "resourceType":this.ruleForm.resourceType,
                    "resourceUrl":this.ruleForm.resourceUrl,
                    "parentResourceId":this.ruleForm.parentResourceId,
                    "iconCode":this.ruleForm.iconCode
                }).then((res) => {
                    this.$emit('getTree')
                    this.getList()
                    this.$emit('hide',true)
                    this.loading = false
                }).catch((error) => {
                    this.loading = false
                })
            },
            hideIcon(){
                this.iconDrawer = false
            }
        },
        created(){
            this.getList()
        },
        watch:{
            type(){
                this.getList()
            }
        }
    }
</script>

<style scoped>

</style>